<template>
  <v-row justify="center">
    <v-dialog :value="true" max-width="600" @input="$emit('closeDialog')">
      <v-card class="pa-8">
        <v-row class="justify-end">
          <v-btn icon color="primary" @click="$emit('closeDialog')">
            <v-icon v-text="'close'" />
          </v-btn>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-1 px-3">
            <h2 class="d-block mb-4" v-text="$t(`project.competition.match.${teamIndex === 0 ? 'editLocalTeam' : 'editVisitorTeam'}`)" />
          </v-col>
          <v-col cols="12">
            <v-tabs v-model="tab" color="secondary">
              <v-tabs-slider color="primary" />
              <v-tab v-for="{ id, name } in tabs" :key="id" v-text="name" />
            </v-tabs>
            <v-divider class="mb-4" />

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="{ id } in tabs" :key="id">
                <div v-if="id === 'teams'">
                  <v-autocomplete
                    v-model="form.selectedTeamId"
                    :label="$t('project.competition.match.searchByTeamName')" :items="teamsItems"
                    outlined dense class="my-1"
                  />
                </div>
                <div v-if="id === 'origin'">
                  <div v-for="(item) in originsItems" :key="item.id" style="cursor: pointer" class="pt-4" @click="selectedOriginItem = item">
                    <p>{{ item.name }}</p>
                    <v-divider />
                  </div>
                  <div v-if="selectedOriginItem && selectedOriginItem.stageId != 'elimination'">
                    <v-select
                      v-if="groupItems.length"
                      v-model="form.selectedGroupId" :items="groupItems"
                      :label="$t('project.sections.groups')" append-icon="mdi-chevron-down"
                    />
                    <v-select
                      v-model="form.selectedGroupPosition" :items="positionItems"
                      :label="$t('common.position')" append-icon="mdi-chevron-down"
                    />
                  </div>
                  <div v-if="selectedOriginItem && selectedOriginItem.stageId === 'elimination'">
                    <v-select
                      v-model="form.selectedFixtureId" :items="matchesItems"
                      :label="$t('project.competition.headers.game')" append-icon="mdi-chevron-down"
                    />
                    <v-select
                      v-model="form.selectedFixturePosition"
                      :label="$t('common.position')" :items="positionResultItems"
                      append-icon="mdi-chevron-down"
                    />
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
        <v-card-actions class="justify-space-around align-center">
          <v-btn color="primary" min-width="12rem" :disabled="$v.form.$invalid" @click="assign" v-text="$t('common.assign')" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import { requiredIf } from 'vuelidate/lib/validators'
import matchesAndRoundsMixin from '@/modules/competition/matches/matchesAndRoundsMixin'
import i18n from '@/plugins/i18n'

export default {
  name: 'EditTeamOrOriginMatchDialog',
  mixins: [matchesAndRoundsMixin],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    match: { type: Object, required: true },
    teamIndex: { type: Number, required: true },
  },
  data: () => ({
    tab: null,
    selectedOriginItem: null,
    form: {
      selectedTeamId: null,
      selectedGroupId: null,
      selectedGroupPosition: null,
      selectedFixtureId: null,
      selectedFixturePosition: null,
    },
  }),
  validations() {
    return {
      form: {
        selectedTeamId: { required: requiredIf(() => this.tabs[this.tab]?.id === 'teams') },
        selectedGroupId: { required: requiredIf(() => this.tabs[this.tab]?.id === 'origin' && this.selectedOriginItem?.stageId === 'rrobin') },
        selectedGroupPosition: { required: requiredIf(() => this.tabs[this.tab]?.id === 'origin' && this.selectedOriginItem?.stageId === 'rrobin') },
        selectedFixtureId: { required: requiredIf(() => this.tabs[this.tab]?.id === 'origin' && this.selectedOriginItem?.stageId === 'elimination') },
        selectedFixturePosition: { required: requiredIf(() => this.tabs[this.tab]?.id === 'origin' && this.selectedOriginItem?.stageId === 'elimination') },
      },
    }
  },
  computed: {
    ...mapGetters('competition', ['groups', 'isRegularNba', 'matches', 'teams', 'cohorts']),
    tabs: ({ match }) => [
      { id: 'teams', name: i18n.t('project.competition.match.byTeams') },
      ...(match.stageId === 'elimination' ? [{ id: 'origin', name: i18n.t('project.competition.match.byOrigin') }] : []),
    ],
    teamsItems: ({ teams, match }) => teams
      .filter(t => t.cohortId === match.cohortId && t.dataByStageId?.[match.stageId]?.groupId === match.groupId)
      .filter(t => !Object.keys(match.teamsById).includes(t.id))
      .map(t => ({ text: t.name, value: t.id })),
    originsItems({ match, rounds, isRegularNba }) {
      const indexEliminationMatch = rounds.find(r => r.id === match.roundId).index
      return [
        { id: 'rrobin', name: isRegularNba ? i18n.t('project.competition.stages.regularSeason') : i18n.t('project.competition.stages.rrobin') },
        ...rounds.filter(r => r.cohortId === match.cohortId && r.stageId === 'elimination' && r.index > indexEliminationMatch)
          .filter((v, i, a) => a.findIndex(v2 => (v2.index === v.index)) === i).reverse()
          .map(r => ({ id: r.id, name: r.name, stageId: r.stageId })),
      ]
    },
    positionItems({ cohorts, match }) {
      const teamsPerGroup = cohorts.find(c => c.id === match.cohortId)?.dataByStageId?.rrobin?.teamsPerGroup
      return [...Array(teamsPerGroup)].map((_, i) => ({ text: i + 1, value: i }))
    },
    positionResultItems: () => [
      { text: i18n.t('project.competition.match.winner'), value: 0 },
      { text: i18n.t('project.competition.match.loser'), value: 1 },
    ],
    currentGroups: ({ groups, match }) => groups.filter(group => group.cohortId === match.cohortId && group.stageId === 'rrobin'),
    groupItems: ({ currentGroups }) => currentGroups.map(item => ({ text: item.name, value: item.id })),
    matchesItems({ matches, selectedOriginItem }) {
      return matches.filter(m => m.roundId === selectedOriginItem.id)
        .map(m => ({ text: this.getMatchName(m), value: m.fixtureId }))
    },
  },
  methods: {
    async assign() {
      if (this.tabs[this.tab]?.id === 'teams') await this.changeTeam()
      else await this.changeOrigin()
      this.$emit('closeDialog')
    },
    async changeTeam() {
      const { organizationId, projectId, teamIndex, match } = this
      const newTeam = this.teams.find(t => t.id === this.form.selectedTeamId && t.cohortId === match.cohortId)
      await this.runAsync(() => this.$store.dispatch('competition/matchChangeTeam', { organizationId, projectId, match: this.match, newTeam, teamIndex }))
    },
    async changeOrigin() {
      const { organizationId, projectId, teamIndex } = this
      const newOrigin = {
        type: this.selectedOriginItem.stageId === 'elimination' ? 'fixture' : 'group',
        id: this.selectedOriginItem.stageId === 'elimination' ? this.form.selectedFixtureId : this.form.selectedGroupId,
        position: this.selectedOriginItem.stageId === 'elimination' ? this.form.selectedFixturePosition : this.form.selectedGroupPosition,
        index: this.teamIndex,
      }
      console.log(newOrigin, this.form.selectedGroupPosition)
      await this.runAsync(() => this.$store.dispatch('competition/matchChangeTeamOrigin', { organizationId, projectId, match: this.match, teamIndex, newOrigin }))
    },
  },
}
</script>
